import React from 'react';
import dynamic from 'next/dynamic';

const MyApp = dynamic(() => import('./myApp'), { ssr: false });

function App(props) {
  return <MyApp {...props} />;
}

export default App;
